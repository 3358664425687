<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      :style="{
        backgroundImage: `url(${backgroundImage})`,
      }"
    >
      <!-- begin:: Content -->
      <div
        class="container d-flex flex-row-fluid flex-column justify-content-md-center p-12"
      >
        <h1
          class="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12"
        >
          Oops!
        </h1>
        <p class="font-weight-boldest display-4">
          Algo salió mal.
        </p>
        <p class="font-size-h3">
          La página que estas buscando no existe
          <br />y no sabemos como llegaste aquí. <br />Presiona el siguiente
          botón para regresar al sistema.
        </p>
        <router-link
          to="/dashboard"
          custom
          v-slot="{ href, navigate, isActive }"
        >
          <b-button
            squared
            variant="info"
            class="mr-auto"
            :active="isActive"
            :href="href"
            @click="navigate"
            >Ir al inicio</b-button
          >
        </router-link>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-5.scss";
</style>

<script>
export default {
  name: "Error-5",
  title: "Página no encontrada | Portal Estudiantil ITR",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg5.jpg";
    },
  },
};
</script>
